import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import { AuthLayout } from "./Component/AuthLayout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { HomeLayout } from "./Component/Homelayout";

import { ProtectedLayout } from "./Component/ProtectedLayout";
import Report from "./pages/Report";
import { Grafica } from "./pages/Grafica";

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 1000)
  );

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
    localStorage.setItem("uid", uid);
    localStorage.setItem("email", user.email);
  }
});
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

      
      </Route>
      <Route path="/grafica/:restaurante" element={<Grafica />} />
      <Route path="/reports" element={<ProtectedLayout />}>
        <Route path="report" element={<Report></Report>} />
        {/* <Route path="admin" element={<Report />} /> */}
        {/* <Route path="calendar" element={<CalendarRestaurante />} /> */}
        {/* <Route path="settings" element={<SettingsPage />} /> */}
      </Route>
    </Route>
  )
);
